.curso-content4 {
    
    width: 85%;
    margin: 60px auto;
   
}
.curso-title4{
    font-family: "NeueMachine",sans-serif;
    font-size: 86px;
    font-weight: 900;
    line-height: 97.92px;
    text-align: left;
    color: rgba(4, 4, 4, 1);

}

.curso-header4 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
}

.curso-description4{
    font-family: "NeueMachine",sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 49.68px;
    text-align: justify;

}
.lorena-img {
    height: 180px !important; /* Ajuste o valor conforme necessário */
    object-fit: cover;
}

.footer-line-curso4{
    width: auto;
    height: auto;
    background-color: rgba(0, 68, 214, 1);    

}
.professor-card-group {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    justify-content: space-around;
    margin-top: 20px;
}

.professor-card {
    background-color: rgba(217, 217, 217, 1) !important;
    border: none !important;
    border-radius: 22px !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    margin: 30px 30px 30px 0px;
    text-align: center;
    height: 300px; /* Dimensão especificada */
    display: flex;
    padding: 10px;
    width: 100%;
    border: none;
}

.professor-name {
    font-family: 'NeueMachine', sans-serif;
    color: rgba(0, 0, 0, 1);
    font-size: 25px;
    font-weight: 900;
    line-height: 28.56px;
    text-align: center;

}

.content-modulo4{
    width: 85%;
    margin: auto auto;
    padding-bottom: 30px;   
}

.professor-img {
    border-radius: 15px !important; /* Ajuste o raio da borda conforme necessário */
    width: 100%;
    height: 180px;
}
.ctt24{
    font-family: 'NeueMachine', sans-serif;
    font-size: 36px;
    font-weight: 900;
    line-height: 54px;
    text-align: center;
    color:  rgba(255, 255, 255, 1);
    ;
}

.footer-line31 {
    width: auto;
    height: 90px;
    background-color:rgba(0, 0, 0, 1);
    top: 764px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.carousel-professores .carousel-control-prev-icon,
.carousel-professores .carousel-control-next-icon {
    filter: brightness(0) saturate(100%); /* Transforma a cor em preto */
    /* Outros estilos específicos */
}
.carousel-professores .carousel-indicators{
    margin-bottom: 30px;
    filter: brightness(0) saturate(100%); /* Transforma a cor em preto */

}

.carousel-professores .carousel-item {
   gap: 20px;/* Espaçamento entre os itens do carrossel */
}

@media (max-width: 1050px){
    .curso-title4{
        font-size: 40px;
        line-height: 40px;
    }
    .curso-description4{
        font-size: 22px;
        line-height: 28px;
    }
    .professor-card-group{
        display: none;
    }
    .ctt24{
        display: none;
    }
   

    .carousel-control-prev {
        left: -8%; /* Mova a seta para a esquerda */
       
    }

    .carousel-control-next {
        right: -8%; /* Mova a seta para a direita */
    }
}