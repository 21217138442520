

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(64, 63, 63, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    /* Substitua 'rgba(64, 63, 63, 1)' pela cor desejada */
}

.ms-auto{
    margin-right: 20px;
}
.navbarStyle{
    box-Shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    width: 85%;
    margin: 0 auto;
    margin-Top: 15px;
    border-Radius: 20px;
    
}

.navbar-nav{
    align-items: center;
}

.navbar-sticky {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
    z-index: 1000; /* Ensures the navbar is above other content */
    width: 100%; /* Make sure the navbar stretches the full width */
    margin: 0; /* Remove the margin for a fixed position */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    background-color: rgba(241, 241, 241, 0.68);
    border-radius: 0; /* Remove the border radius for a cleaner look */
}