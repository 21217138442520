.curso-subtitle3{
    font-family: 'NeueMachine', sans-serif;
    font-size: 38px;
    font-weight: 900;
    line-height: 48.96px;
    text-align: left;
    color: rgba(0, 68, 214, 1);
    margin-top: 50px;
}

.pq-text3{
    font-family: 'NeueMachine', sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 47.72px;
    text-align: justify;
    color:  rgba(255, 255, 255, 1);
    margin-bottom: 0px !important;
    padding-bottom: 20px;   

}

.footer-line23 {
    width: auto;
    height: 90px;
    background-color: rgba(0, 68, 214, 1);
    top: 764px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.ctt23{
    font-family: 'NeueMachine', sans-serif;
    font-size: 36px;
    font-weight: 900;
    line-height: 54px;
    text-align: center;
    color:  rgba(255, 255, 255, 1);
    
}

.contact-info23 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 88%;
    margin: 60px auto;
}
.titulo-nefesh23{
    font-family: 'NeueMachine', sans-serif;
    font-size: 96px;
    font-weight: 900;
    line-height: 97.92px;
    text-align: left;
    color: rgba(248, 248, 248, 1);
    padding-top: 40px;
    margin-bottom: 20px;

}

.curso-content3 {
    
    width: 85%;
    margin: 60px auto;
   
}

.curso-title3{
    font-family: "NeueMachine",sans-serif;
    font-size: 86px;
    font-weight: 900;
    line-height: 97.92px;
    text-align: left;
    color: rgba(4, 4, 4, 1);

}

.curso-header3 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
}


.curso-description3{
    font-family: "NeueMachine",sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 49.68px;
    text-align: justify;

}
.content-nefesh3{
    width: 85%;
    margin: auto auto;
}

.footer-line-nefesh3{
    background-color: rgba(0, 0, 0, 1);
    width: auto;
    height: auto;

}
.contact-details23 {
    display: flex;
    align-items: center;
    gap: 20px;
    
}

@media (max-width: 1050px) {
    .curso-header3{
        display: block;
        text-align: left; /* Centraliza o texto no header */

    }
    .curso-title3{
        text-align: left;
    }
    .titulo-nefesh{
        font-size: 60px;
    }
    .titulo-curso09{
        font-size: 75px;
        text-align: left;
    }
    .modulos-group2{
        flex-direction: column;
    }
    .matricula-button{
        width: auto;
    }
    .modulos-group1{
        flex-direction: column;
    }
    .contact-info23{
        flex-direction: column;
    }
    
    .curso-description3{
        font-size: 25px;
        line-height: 28px;
    }
    .nefesh-description1{
        font-size: 25px;
        line-height: 27px;
    }
    .nefesh-rodape1{
        font-size: 20px;
    }
    .titulo-nefesh23{
        font-size: 55px;
    }
    .ctt23{
        display: none;
    }

}