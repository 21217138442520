.curso-content1 {
    
    width: 85%;
    margin: 60px auto;
   
}

.curso-title1{
    font-family: "NeueMachine",sans-serif;
    font-size: 86px;
    font-weight: 900;
    line-height: 97.92px;
    text-align: left;
    color: rgba(4, 4, 4, 1);

}

.curso-header1 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
}


.curso-description1{
    font-family: "NeueMachine",sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 49.68px;
    text-align: justify;

}

.footer-line-curso{
    width: auto;
    height: auto;
    background-color: rgba(0, 68, 214, 1);
    top: 764px;
    
}

.btn-matricula{
    line-height: 36.72px;
    font-size: 25px;
    font-weight: 900;
    font-family: 'NeueMachine', sans-serif;
    background-color: rgba(0, 68, 214, 1);
    color: white; /* Cor do texto */
    border-radius: 15px;
    text-decoration: none; /* Remove o sublinhado */
    width: 226px;
}

.btn-matricula:hover {
    background-color: #0376f0; /* Cor do fundo ao passar o mouse */
    border-color: #0373ea; /* Cor da borda ao passar o mouse */
    color: white;

}

.titulo-curso1 { 
    font-family: 'NeueMachine', sans-serif;
    font-size: 96px;
    font-weight: 900;
    line-height: 97.92px;
    text-align: center;
    color: rgba(234, 234, 234, 1);
    margin-bottom: 20px;
    padding-top: 20px;
}
.content-modulo{
    width: 85%;
    margin: auto auto;
}

.modulos-group1 {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    gap: 20px;
    margin-bottom: 20px;
    
}

.modulo-card {
    background-color: rgba(217, 217, 217, 1);
    border-radius: 21px !important;
    padding: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: auto;
    border: none;
}

.modulo-number {
    color: rgba(121, 121, 121, 1);
    font-family: 'NeueMachine', sans-serif;
    font-size: 54px;
    font-weight: 900;
    line-height: 65.28px;
    text-align: left;
}   

.modulo-text{
    font-family: 'NeueMachine', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: rgba(0, 0, 0, 1);


}

.matricula-button-container {
    text-align: center;
    margin-top: 20px;
    padding-bottom: 20px;
}

.matricula-button1 {
    font-size: 38px;
    font-weight: 500;
    line-height: 48.96px;
    font-family: 'NeueMachine', sans-serif;
    text-decoration: none;
    background-color: rgba(217, 217, 217, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: rgba(37, 34, 34, 1);
    width: 421px;
    height: auto;
    border-radius: 15px;
    padding:10px;


}

.matricula-button:hover {
    background-color:rgb(243, 243, 243) ;
    border-color: rgb(243, 243, 243);
    color: rgba(37, 34, 34, 1);
}

.footer-line3 {
    width: auto;
    height: 90px;
    background-color:rgba(0, 0, 0, 1);
    top: 764px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.ctt2{
    font-family: 'NeueMachine', sans-serif;
    font-size: 36px;
    font-weight: 900;
    line-height: 54px;
    text-align: center;
    color:  rgba(255, 255, 255, 1);
    ;
}

.contact-info2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 88%;
    margin: 60px auto;
}

@media (max-width: 1050px) {
    .curso-header1{
        display: block;
        text-align: left; /* Centraliza o texto no header */

    }
    .curso-title1{
        text-align: left;
    }
    .titulo-nefesh{
        font-size: 60px;
    }
    .titulo-curso1{
        font-size: 75px;
        text-align: left;
    }
    .modulos-group{
        flex-direction: column;
    }
    .matricula-button{
        width: auto;
    }
    .modulos-group1{
        flex-direction: column;
        margin-bottom: 0px;
    }
    .contact-info2{
        flex-direction: column;
        width: 100%;
    }
    
    .curso-description1{
        font-size: 22px;
        line-height: 28px;
    }
    .nefesh-description{
        font-size: 25px;
        line-height: 27px;
    }
    .nefesh-rodape{
        font-size: 20px;
    }
    .titulo-nefesh2{
        font-size: 55px;
    }
    .ctt2{
        display: none;
    }
    .matricula-button1{
        width: 300px;
    }


}