.carousel-thumbnails {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-top: 20px;
}

.thumbnail {
    width: 15%;
    height: 20%;
    object-fit: cover;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s ease;
    border: 2px solid transparent;
}

.thumbnail.active {
    opacity: 1;
    border-color: #007bff; /* Adiciona uma borda azul ao thumbnail ativo */
    border-radius: 2%;
}

.thumbnail:hover {
    opacity: 1;
}

.carousel-inner{
    border-radius: 2%;
}


.carousel-control-prev1{
    left: -8% !important;
    color: #007bff;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    filter: invert(28%) sepia(98%) saturate(7543%) hue-rotate(192deg) brightness(94%) contrast(105%);
    width: 40px;
    height: 40px;
}

.carousel-control-next1{
    right: -8% !important;
    color: #007bff;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease;
}

.carousel-indicators{
    margin-bottom: 0px;
    
}