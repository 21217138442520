/* Home.css */
html {
    scroll-behavior: smooth; /* Adiciona rolagem suave */
}

@font-face {
    font-family: 'NeueMachine';
    src: url('../font/NeueMachina-Regular.otf') format('opentype');
    font-weight: 400; /* Regular */
    font-style: normal;
}

@font-face {
    font-family: 'NeueMachine';
    src: url('../font/NeueMachina-Light.otf') format('opentype');
    font-weight: 300; /* Light */
    font-style: normal;
}

@font-face {
    font-family: 'NeueMachine';
    src: url('../font/NeueMachina-Ultrabold.otf') format('opentype');
    font-weight: 800; /* Ultrabold */
    font-style: normal;
}

.content {
    
    width: 85%;
    margin: 100px auto;
   
}
.content-row {
    display: flex;
    flex-direction: row;
    align-items: center;    
    justify-content: space-between;

} 
.text-content {
    width: 100%;
    text-align: justify;
    margin-right: 10px; /* Espaçamento entre o texto e a imagem */
}


.titleH {
    font-size: 54px;
    font-weight: 900;
    margin-bottom: 20px;
    font-family: 'NeueMachine',sans-serif;
    color: rgba(34, 34, 34, 1);
    line-height: 65.28px;
}

.descriptionH {
    font-size: 30px;
    line-height: 49.68px;
    color: rgba(90, 90, 90, 1);
    font-family: 'NeueMachine',sans-serif;
    font-weight: 400;
    
}

.side-image {
    width: 701px; /* Ajuste o tamanho da imagem conforme necessário */
    height: 543px;
    margin-left: 50px; /* Espaçamento entre a imagem e o texto */
    border-radius: 20px;
}

.line {
    width: 100%;
    height: 1px;
    background-color: rgba(137, 137, 137, 1);
    margin-bottom: 20px; /* Espaço entre a linha e o parágrafo */
}

.dropdown-toggle.titulob{
    font-family: 'NeueMachine',sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 36.72px;
    text-align: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: rgba(0, 68, 214, 1);
    display: flex;
    align-items: center;

}

.dropdown-menu-horizontal {
    display: flex !important;
    flex-direction: row !important;
    border: 1px solid rgba(137, 137, 137, 1) !important;
    justify-content: space-around !important;
    background-color: #f8f9fa !important;
    border-radius: 8px !important;
    padding: 10px !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: rgba(248, 248, 248, 0.65) !important;


    
}
.light {
    font-family: 'NeueMachine',sans-serif;    
    font-size: 35px;
    font-weight: 300;
    line-height: 36.4px;
    text-align: center;
    color: rgba(91, 90, 90, 1);



}
.separator {
    margin: 0 10px; /* Ajuste o espaçamento ao redor do separador */
    font-weight: 100; /* Certifique-se de que o separador não esteja em negrito */
    color: #333; /* Cor do separador, ajustável conforme a necessidade */
    font-size: 26px;
    align-items: baseline;
}

.bold {
    font-family: 'NeueMachine',sans-serif;    
    font-size: 40px;
    font-weight: 800;
    line-height: 36.4px;
    text-align: center;
    color: rgba(91, 90, 90, 1);

}

.dropdown-item {
    display: flex;
    align-items: center;
    justify-content: center;
}


.footer-line {
    width: auto;
    height: 34px;
    background-color: rgba(0, 68, 214, 1);
    top: 764px;
}

.footer-line22 {
    width: auto;
    height: 90px;
    background-color: rgba(0, 68, 214, 1);
    top: 764px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.contact-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 84%;
    margin: 60px auto;
}

.contact-details {
    display: flex;
    align-items: center;
    gap: 20px;
    
}

.ctt-ctt{
    font-family: 'NeueMachine', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 54px;
    text-align: center;
    color: rgba(255, 255, 255, 1);

}

.contact-item{
    gap: 5px;
    display: flex;
    align-items: baseline;
}

.contact-icon {
    font-size: 33px; /* Ajuste o tamanho do ícone conforme necessário */
    color: white;
}
.ctt{
    font-family: "NeueMachine", sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 54px;
    text-align: center;
    color: rgba(255, 255, 255, 1);

}

.fundo-video{
    width: auto;
    height: auto;
    background-color: rgba(4, 4, 4, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    
    
}
.contet-video{
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin: 60px auto;
    display: flex;

}

.text-video{
    width: 100%;
    text-align: justify;
    margin-right: 20px;
    color: rgba(248, 248, 248, 1);
}
.tituloV{
    font-family: "NeueMachine",sans-serif;
    font-size: 51px;
    font-weight: 900;
    line-height: 62.22px;
    text-align: left;
    color: rgba(248, 248, 248, 1);

}

.descricaoV{
    font-family: "NeueMachine", sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 49.68px;
    text-align: justify;

}
.video {
    width:auto;
}

.video iframe {
    border-radius: 25px; /* Adiciona border-radius ao vídeo */
}
.video-mobile iframe {
    border-radius: 25px; /* Adiciona border-radius ao vídeo */
}

.mobile-only {
    display: none;
}
.video-mobile{
    display: none;
}

.video{
    display: block;
}


.somente-video {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 60px auto; /* Ajuste a margem conforme necessário */
    padding: 20px; /* Adicione preenchimento para dar espaço ao redor do vídeo */
    border-radius: 15px; /* Bordas arredondadas */
}

.somente-video video {
    width: 70%;
    height: auto;
    border-radius: 10px; /* Bordas arredondadas para o vídeo */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra suave */

}

.tituloV2{
    font-family: "NeueMachine", sans-serif;
    font-size: 96px;
    font-weight: 900;
    line-height: 97.92px;
    color: rgba(248, 248, 248, 1);


}

.descricaoV2{
    font-family: "NeueMachine", sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 49.68px;
    text-align: justify;

}

/* Esconde a imagem na versão mobile */
.desktop-only {
    display: block;
}

.imagemls{
    width: auto;
    height: 700px;
    border-radius: 20px
}

.titulo-card{
    font-family: "NeueMachine", sans-serif;
    font-size: 89px;
    font-weight: 900;
    line-height: 86.7px;
    text-align: center;
    color: rgba(4, 4, 4, 1);
    margin-bottom: 50px; /* Ajuste a margem inferior para criar o espaçamento */


}

.alo{
    width: 85%;
    margin: 60px auto;
}

.card-motivo {
    background-color:rgba(217, 217, 217, 1) !important;
    border-radius: 22px !important;
    padding: 5px !important;
    margin: 30px !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    width: auto !important;
    border: none !important;
    
}

.card-number {
    color: rgba(0, 68, 214, 1) !important;
    font-family: "NeueMachine", sans-serif !important;
    font-size: 44px !important;
    font-weight: 900 !important;
    line-height: 65.28px !important;
    text-align: start !important;

}

.card-text{
    font-family: "NeueMachine",sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 34.68px;
    text-align: left;

}

.contet-depo{
    width: 85%;
    margin: 60px auto;
}

.tituloD{
    font-family: "NeueMachine", sans-serif;
    font-size: 75px;
    font-weight: 900;
    line-height: 86.7px;
    text-align: left;
    color: rgba(255, 255, 255, 1);

}

.depo-group {
    display: flex !important;
    flex-wrap: wrap !important; /* Permite que os itens se reorganizem em várias linhas */
    justify-content: space-between !important; /* Espaça os itens igualmente, preenchendo os espaços */
    gap: 20px !important; /* Espaçamento entre os cartões */
    flex-direction: column;
}
.depo-card {
    background-color:rgba(217, 217, 217, 1) !important;
    border: none !important;
    border-radius: 22px !important;
    padding: 20px !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    margin: 30px;
    height: 30% !important;
    flex: 1 1 calc(33% - 20px); /* Ajuste para 3 cards por linha */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animação suave */

}

.depo-card:hover {
    transform: translateY(-5px); /* Elevação suave */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15); /* Sombra mais pronunciada no hover */
}
.depo-card2:hover {
    transform: translateY(-5px); /* Elevação suave */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15); /* Sombra mais pronunciada no hover */
}
.depo-card3:hover {
    transform: translateY(-5px); /* Elevação suave */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15); /* Sombra mais pronunciada no hover */
}
.depo-card2{
    background-color:rgba(217, 217, 217, 1) !important;
    border: none !important;
    border-radius: 22px !important;
    padding: 5px !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    margin: 30px;
    height: 60% !important;
    flex: 1 1 calc(33% - 20px); /* Ajuste para 3 cards por linha */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animação suave */
}
.depo-card2 p {
    
    color:rgba(66, 66, 66, 1);
    font-family: "NeueMachine", sans-serif;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 37.27px;
    text-align: justify;

}
.depo-card2 small {
    color: rgba(0, 68, 214, 1) !important;
    font-family: "NeueMachine", sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 40.8px;
    text-align: end !important;

}
.depo-card2 .card-footer {
    text-align: end; /* Alinha o texto à direita */
    background-color: transparent; /* Remove a cor de fundo */
    border-top: none; /* Remove a borda superior */
}



.depo-card p {
    
    color:rgba(66, 66, 66, 1);
    font-family: "NeueMachine", sans-serif;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 37.27px;
    text-align: justify;

}

.depo-card small {
    color: rgba(0, 68, 214, 1) !important;
    font-family: "NeueMachine", sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 40.8px;
    text-align: end !important;

}
.depo-card .card-footer {
    text-align: end; /* Alinha o texto à direita */
    background-color: transparent; /* Remove a cor de fundo */
    border-top: none; /* Remove a borda superior */
}

.depo-card3{
    background-color:rgba(217, 217, 217, 1) !important;
    border: none !important;
    border-radius: 22px !important;
    padding: 5px !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    margin: 30px;
    height: 60% !important;
    flex: 1 1 calc(33% - 20px); /* Ajuste para 3 cards por linha */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animação suave */
}
.depo-card3 p {
    
    color:rgba(66, 66, 66, 1);
    font-family: "NeueMachine", sans-serif;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 37.27px;
    text-align: justify;

}
.depo-card3 small {
    color: rgba(0, 68, 214, 1) !important;
    font-family: "NeueMachine", sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 40.8px;
    text-align: end !important;

}
.depo-card3 .card-footer {
    text-align: end; /* Alinha o texto à direita */
    background-color: transparent; /* Remove a cor de fundo */
    border-top: none; /* Remove a borda superior */
}



.depo-card p {
    
    color:rgba(66, 66, 66, 1);
    font-family: "NeueMachine", sans-serif;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 37.27px;
    text-align: justify;

}

.depo-card small {
    color: rgba(0, 68, 214, 1) !important;
    font-family: "NeueMachine", sans-serif;
    font-size: 30px;
    font-weight: 900;
    line-height: 40.8px;
    text-align: end !important;

}
.depo-card .card-footer {
    text-align: end; /* Alinha o texto à direita */
    background-color: transparent; /* Remove a cor de fundo */
    border-top: none; /* Remove a borda superior */
}
@media (max-width: 1200px) {
    .text-content {
        width: 100%;
    }
    .side-image {
        margin-left: 0;
        margin-top: 20px;
        width: 300px;
        height: 200px;
    }
    .content-row {
        flex-direction: column; /* Muda a direção dos itens para coluna na versão mobile */
    }
    .mobile-only {
        display: block; /* Exibe a imagem na versão mobile */
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
    .desktop-only {
        display: none; /* Esconde a imagem na versão desktop */
    }
    .mt-3 {
        margin-top: 1rem; /* Ajusta a margem superior para dispositivos móveis */
        display: flex;
        justify-content: center; /* Centraliza o dropdown */
    }
    .text-video{
        width: 100%;
        text-align: center;
    }
    .video-mobile{
        display: block; /* Exibe a imagem na versão mobile */
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
    .video{
        display: none;
    }
    .contet-video {
        flex-direction: column; /* Muda a direção dos itens para coluna na versão mobile */
        align-items: center; /* Centraliza o conteúdo */
        text-align: center; /* Centraliza o texto */
    }
    .depo-group{
        flex-direction: column;
    }
    .depo-card{
        height: auto;
    }
    .depo-card p{
        font-size: 18px;
    }
    .depo-card2{
        height: auto;
    }
    .depo-card2 p{
        font-size: 18px;
    }
    .somente-video video {
        width: 100%;
        height: auto;
        border-radius: 10px; /* Bordas arredondadas para o vídeo */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra suave */
    
    }
    .contact-icon{
        font-size: 25px;
    }
    .ctt-ctt{
        font-size: 17px;
    }
    

}

@media (max-width: 1050px) {
    .content{
        margin: 30px auto;
    }
    .titleH {
        font-size: 34px;
        
    }
    .descriptionH {
        font-size: 20px;
        line-height: 27px;
    }
    .dropdown-toggle.titulob {
        font-size: 1.5rem;
    }
    .footer-line {
        height: 20px;
    }
    .content-row {
        flex-direction: column; /* Muda a direção dos itens para coluna na versão mobile */
    }
    .mobile-only {
        display: block; /* Exibe a imagem na versão mobile */
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
    .desktop-only {
        display: none; /* Esconde a imagem na versão desktop */
    }
    .mt-3 {
        margin-top: 1rem; /* Ajusta a margem superior para dispositivos móveis */
        display: flex;
        justify-content: center; /* Centraliza o dropdown */
    }
    .video{
        display: none;
    }
    .video-mobile{
        display: block; /* Exibe a imagem na versão mobile */
        width: 100%;
        height: auto;
       
    }
    .tituloV{
        font-size: 30px;
        line-height: 40px;
    }
    .descricaoV{
        font-size: 20px;
        line-height: 27px;
    }
    .contet-video{
        display: block;
        
    }
    .imagemls{
        display: none;
    }
    .descricaoV2{
        font-size: 20px;
        line-height: 27px;
    }
    .titulo-card{
        font-size: 50px;
        line-height: 50px;
        text-align: left;
    }
    .card-motivo{
        padding: 5px !important;
        margin: 15px !important;
    }
    .card-motivo p {
        font-size: 20px;
    }
    .card-number {
        text-align: center !important;
        display: block;
    }
    .tituloD{
        font-size: 50px;
    }
    .depo-group{
        flex-direction: column;
    }
    .depo-card{
        height: auto;
    }
    .depo-card p{
        font-size: 18px;
        line-height: 27px;
    }
    .depo-card2{
        height: auto;
    }
    .depo-card2 p{
        font-size: 18px;
        line-height: 27px;

    }
    .contact-info{
        flex-direction: column;
        width: 100%;
        
    }
    .contact-details{
        gap: 30px;
        display: flex;
    }
    .tituloV2{
        font-size: 49px;
        text-align: left;
    }
    .ctt{
        display: none;
    }
    .dropdown-menu-horizontal{
        display: flex  !important;
        flex-direction: column !important;
        align-items: center !important;
        padding: 0px !important;
        transform: translate3d(0px, 56.6667px, 0px)!important;
        
    }
    .dropdown-item{
        display: flex !important;
        justify-content: flex-start;


    }
    .separator {
        display: none;
    }
    .contact-icon{
        font-size: 25px;
    }
    .ctt-ctt{
        font-size: 19px;
    }
    
}   