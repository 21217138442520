.carousel-container {
    width: 100%;
    margin: 0 auto;
}

.carousel-image {
    width: 100%;
    height: auto;
    border-radius: 10px; /* Para adicionar bordas arredondadas às imagens */
    object-fit: cover;
}

.slick-slide {
    padding: 0 5px; /* Espaçamento entre os slides */
}

.slick-list {
    margin: 0 -10px; /* Para que o carrossel alinhe corretamente sem cortar */
}
