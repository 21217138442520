.footer-line-nefesh{
    background-color: rgba(0, 0, 0, 1);
    width: auto;
    height: auto;

}
.curso-title09{
    font-family: "NeueMachine",sans-serif;
    font-size: 86px;
    font-weight: 900;
    line-height: 97.92px;
    text-align: left;
    color: rgba(4, 4, 4, 1);

}
.curso-header09  {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
}


.curso-description09{
    font-family: "NeueMachine",sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 49.68px;
    text-align: justify;

}


.content-nefesh1{
    width: 85%;
    margin: auto auto;
}

.titulo-nefesh1{
    font-family: 'NeueMachine', sans-serif;
    font-size: 96px;
    font-weight: 900;
    line-height: 97.92px;
    text-align: left;
    color: rgba(248, 248, 248, 1);
    padding-top: 40px;
    margin-bottom: 20px;

}

.nefesh-description1{
    font-family: 'NeueMachine', sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 47.72px;
    text-align: justify;
    color:rgba(255, 255, 255, 1);

}

.nefesh-rodape1{
    font-family: 'NeueMachine', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 31.82px;
    text-align: center;
    color:rgba(255, 255, 255, 1);
    padding-top: 20px;
    padding-bottom: 30px;
    margin-bottom: 0px !important;
}
.titulo-curso09{ 
    font-family: 'NeueMachine', sans-serif;
    font-size: 96px;
    font-weight: 900;
    line-height: 97.92px;
    text-align: center;
    color: rgba(234, 234, 234, 1);
    margin-bottom: 20px;
    padding-top: 20px;
}

.modulos-group2 {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    gap: 20px;
    padding-bottom: 50px;
}

/* Personalização geral do pop-up */
.custom-popup {
    background-color: #f9f9f9; /* Cor de fundo */
    border: 2px solid #4c60af; /* Borda */
    border-radius: 15px; /* Arredondamento */
    padding: 20px; /* Espaçamento interno */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Sombra */
}

/* Estilo do título */
.custom-title {
    font-family: 'NeueMachine', sans-serif ;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

/* Estilo do texto de conteúdo */
.custom-content {
    font-family: 'NeueMachine', sans-serif;
    font-size: 1rem;
    color: #666;
}

/* Botão de confirmação */
.custom-confirm-button {
    font-family: 'NeueMachine', sans-serif;
    background-color: #4caf50; /* Verde */
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-confirm-button:hover {
    background-color: #45a049; /* Cor mais escura no hover */
}

/* Botão de cancelamento */
.custom-cancel-button {
    font-family: 'NeueMachine', sans-serif;
    background-color: #f44336; /* Vermelho */
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-cancel-button:hover {
    background-color: #e53935; /* Cor mais escura no hover */
}

.custom-actions {
    display: flex;
    justify-content: center; /* Centraliza os botões */
    gap: 20px; /* Espaçamento entre os botões */
    margin-top: 15px; /* Espaçamento superior */
}

/* Estilo geral da janela (popup) */
.custom-popup-cancel {
    background-color: #f8f9fa; /* Fundo claro */
    border: 2px solid #4c60af; /* Borda verde */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Estilo do título */
.custom-title-cancel {
    font-family: "NeueMachine", sans-serif;
    font-size: 1.5rem;
    color: #333; /* Verde */
    margin-bottom: 10px;
}

/* Estilo do texto */
.custom-content-cancel {
    font-family: "NeueMachine", sans-serif;
    font-size: 1rem;
    color: #495057; /* Cinza */
    margin-bottom: 20px;
}

/* Estilo do botão */
.custom-confirm-button-cancel {
    background-color: #28a745; /* Verde */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
}

.custom-confirm-button-cancel:hover {
    background-color: #218838; /* Verde escuro no hover */
}


@media (max-width: 1050px) {
    .curso-header09{
        display: block;
        text-align: left; /* Centraliza o texto no header */

    }
    .curso-title09{
        text-align: left;
    }
    .titulo-nefesh{
        font-size: 60px;
    }
    .titulo-curso09{
        font-size: 75px;
        text-align: left;
    }
    .modulos-group2{
        flex-direction: column;
    }
    .matricula-button{
        width: auto;
    }
    .modulos-group1{
        flex-direction: column;
    }
    .contact-info2{
        flex-direction: column;
    }
    
    .curso-description09{
        font-size: 25px;
        line-height: 28px;
    }
    .nefesh-description1{
        font-size: 25px;
        line-height: 27px;
    }
    .nefesh-rodape1{
        font-size: 20px;
    }
    .titulo-nefesh1{
        font-size: 55px;
    }
    .ctt233{
        display: none;
    }

}