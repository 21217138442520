.curso-content {
    
    width: 85%;
    margin: 60px auto;
   
}

.curso-title{
    font-family: "NeueMachine",sans-serif;
    font-size: 86px;
    font-weight: 900;
    line-height: 97.92px;
    text-align: left;
    color: rgba(4, 4, 4, 1);

}

.curso-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
}

.curso-header12 {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
    gap: 20px;
}



.curso-description{
    font-family: "NeueMachine",sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 49.68px;
    text-align: justify;

}

.footer-line-curso{
    width: auto;
    height: auto;
    background-color: rgba(0, 68, 214, 1);
    top: 764px;
    
}

.btn-matricula1{
    line-height: 36.72px;
    font-size: 25px;
    font-weight: 900;
    font-family: 'NeueMachine', sans-serif;
    background-color: rgba(0, 68, 214, 1);
    color: white; /* Cor do texto */
    border-radius: 15px;
    text-decoration: none; /* Remove o sublinhado */
    width: 226px;
}
.btn-matricula12{
    line-height: 36.72px;
    font-size: 25px;
    font-weight: 900;
    font-family: 'NeueMachine', sans-serif;
    background-color: rgba(0, 68, 214, 1);
    color: white; /* Cor do texto */
    border-radius: 15px;
    text-decoration: none; /* Remove o sublinhado */
    width: 226px;
}


.btn-matricula:hover {
    background-color: #0376f0; /* Cor do fundo ao passar o mouse */
    border-color: #0373ea; /* Cor da borda ao passar o mouse */
    color: white;

}

.titulo-curso { 
    font-family: 'NeueMachine', sans-serif;
    font-size: 96px;
    font-weight: 900;
    line-height: 97.92px;
    text-align: center;
    color: rgba(234, 234, 234, 1);
    margin-bottom: 20px;
    padding-top: 20px;
}
.content-modulo{
    width: 85%;
    margin: auto auto;
}

.modulos-group {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    gap: 20px;
    
}

.modulo-card {
    background-color: rgba(217, 217, 217, 1);
    border-radius: 21px !important;
    padding: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: auto;
    border: none;
}

.modulo-number {
    color: rgba(121, 121, 121, 1);
    font-family: 'NeueMachine', sans-serif;
    font-size: 54px;
    font-weight: 900;
    line-height: 65.28px;
    text-align: left;
}   

.modulo-text{
    font-family: 'NeueMachine', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: rgba(0, 0, 0, 1);


}

.matricula-button-container {
    text-align: center;
    margin-top: 20px;
    padding-bottom: 20px;
}

.matricula-button {
    font-size: 38px;
    font-weight: 500;
    line-height: 48.96px;
    font-family: 'NeueMachine', sans-serif;
    text-decoration: none;
    background-color: rgba(217, 217, 217, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: rgba(37, 34, 34, 1);
    width: 421px;
    height: auto;
    border-radius: 15px;
    padding:10px;


}

.matricula-button:hover {
    background-color:rgb(243, 243, 243) ;
    border-color: rgb(243, 243, 243);
    color: rgba(37, 34, 34, 1);
}

.footer-line-nefeshcc{
    background-color: rgba(0, 0, 0, 1);
    width: auto;
    height: auto;

}

.content-nefesh{
    width: 85%;
    margin: auto auto;
}

.titulo-nefesh{
    font-family: 'NeueMachine', sans-serif;
    font-size: 96px;
    font-weight: 900;
    line-height: 97.92px;
    text-align: left;
    color: rgba(248, 248, 248, 1);
    padding-top: 40px;
    margin-bottom: 20px;

}


.nefesh-description6{
    font-family: 'NeueMachine', sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 47.72px;
    text-align: justify;
    color:rgba(255, 255, 255, 1);

}

.nefesh-rodape{
    font-family: 'NeueMachine', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 31.82px;
    text-align: center;
    color:rgba(255, 255, 255, 1);
    padding-top: 20px;
    padding-bottom: 30px;
    margin-bottom: 0px !important;
}

.modulos-group1 {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    gap: 20px;
    padding-bottom: 50px;
}

.footer-line-cc{
    width: auto;
    height: 20px;
    background-color:rgba(0, 68, 214, 1);
    
}

.curso-subtitle{
    font-family: 'NeueMachine', sans-serif;
    font-size: 38px;
    font-weight: 900;
    line-height: 48.96px;
    text-align: left;
    color: rgba(0, 68, 214, 1);
    margin-top: 50px;
}



.pq-text{
    font-family: 'NeueMachine', sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 47.72px;
    text-align: justify;
    color:  rgba(255, 255, 255, 1);
    margin-bottom: 0px !important;
    padding-bottom: 20px;
}

.footer-line2 {
    width: auto;
    height: 90px;
    background-color: rgba(0, 68, 214, 1);
    top: 764px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.ctt233{
    font-family: 'NeueMachine', sans-serif;
    font-size: 36px;
    font-weight: 900;
    line-height: 54px;
    text-align: center;
    color:  rgba(255, 255, 255, 1);
    
}

.contact-info22 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 88%;
    margin: 60px auto;
}
.titulo-nefesh2{
    font-family: 'NeueMachine', sans-serif;
    font-size: 93px;
    font-weight: 900;
    line-height: 97.92px;
    text-align: left;
    color: rgba(248, 248, 248, 1);
    padding-top: 40px;
    margin-bottom: 20px;

}

.contact-details a {
    text-decoration: none; /* Remove o sublinhado dos links */
    color: inherit;        /* Mantém a cor do texto, herdando do elemento pai */
}

.contact-details a:hover {
    text-decoration: none; /* Garante que o sublinhado não apareça ao passar o mouse */
}

.ctt-ctt1{
    font-family: 'NeueMachine', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 54px;
    text-align: center;
    color: rgba(255, 255, 255, 1);

}

.contact-icon1 {
    font-size: 33px; /* Ajuste o tamanho do ícone conforme necessário */
    color: white;
}

.grey1{
    width: 37%;
    height: 20%;
    border-radius: 5%;
}
.grey2{
    width: 37%;
    height: 20%;
    border-radius: 5%;
}
.headgp{
    display: flex;
    justify-content: space-around;
    padding-bottom: 2%;
    padding-top: 2%;


}

.btn-mostrar-mais{
    line-height: 36.72px;
    font-size: 25px;
    font-weight: 900;
    font-family: 'NeueMachine', sans-serif;
    background-color: rgba(0, 68, 214, 1);
    color: white; /* Cor do texto */
    border-radius: 15px;
    width: 226px;
    border: #0376f0;

}
.btn-mostrar-mais:hover {
    background-color: #0056b3;
    transform: scale(1.05); /* Efeito de crescimento ao passar o mouse */
}

.btn-mostrar-mais:active {
    background-color: #004085;
    transform: scale(0.98); /* Efeito de clique */
}

.carousel-container {
    width: 100%;
    margin: 0 auto;
}

.carousel-image {
    width: 100%;
    height: auto;
    border-radius: 10px; /* Para adicionar bordas arredondadas às imagens */
    object-fit: cover;
}

.slick-slide {
    padding: 0 5px; /* Espaçamento entre os slides */
}

.slick-list {
    margin: 0 -10px; /* Para que o carrossel alinhe corretamente sem cortar */
}

.custom-popup {
    background-color: #f9f9f9; /* Cor de fundo */
    border: 2px solid #4c60af; /* Borda */
    border-radius: 15px; /* Arredondamento */
    padding: 20px; /* Espaçamento interno */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Sombra */
}

/* Estilo do título */
.custom-title {
    font-family: 'NeueMachine', sans-serif ;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

/* Estilo do texto de conteúdo */
.custom-content {
    font-family: 'NeueMachine', sans-serif;
    font-size: 1rem;
    color: #666;
}

/* Botão de confirmação */
.custom-confirm-button {
    font-family: 'NeueMachine', sans-serif;
    background-color: #4caf50; /* Verde */
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-confirm-button:hover {
    background-color: #45a049; /* Cor mais escura no hover */
}

/* Botão de cancelamento */
.custom-cancel-button {
    font-family: 'NeueMachine', sans-serif;
    background-color: #f44336; /* Vermelho */
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-cancel-button:hover {
    background-color: #e53935; /* Cor mais escura no hover */
}

.custom-actions {
    display: flex;
    justify-content: center; /* Centraliza os botões */
    gap: 20px; /* Espaçamento entre os botões */
    margin-top: 15px; /* Espaçamento superior */
}

/* Estilo geral da janela (popup) */
.custom-popup-cancel {
    background-color: #f8f9fa; /* Fundo claro */
    border: 2px solid #4c60af; /* Borda verde */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Estilo do título */
.custom-title-cancel {
    font-family: "NeueMachine", sans-serif;
    font-size: 1.5rem;
    color: #333; /* Verde */
    margin-bottom: 10px;
}

/* Estilo do texto */
.custom-content-cancel {
    font-family: "NeueMachine", sans-serif;
    font-size: 1rem;
    color: #495057; /* Cinza */
    margin-bottom: 20px;
}

/* Estilo do botão */
.custom-confirm-button-cancel {
    background-color: #28a745; /* Verde */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
}

.custom-confirm-button-cancel:hover {
    background-color: #218838; /* Verde escuro no hover */
}




@media (max-width: 1200px){

}

@media (max-width: 1050px) {
    .curso-header{
        display: block;
        text-align: left; /* Centraliza o texto no header */

    }
    .curso-title{
        text-align: left;
    }
    .titulo-nefesh{
        font-size: 60px;
    }
    .titulo-curso{
        font-size: 75px;
        text-align: left;
    }
    .modulos-group{
        flex-direction: column;
    }
    .matricula-button{
        width: auto;
    }
    .modulos-group1{
        flex-direction: column;
    }
    .contact-info2{
        flex-direction: column;
    }
    
    .curso-description{
        font-size: 22px;
        line-height: 28px;
    }
    .nefesh-description6{
        font-size: 20px;
        line-height: 27px;
    }
    .nefesh-rodape{
        font-size: 20px;
    }
    .titulo-nefesh2{
        font-size: 55px;
    }
    .ctt233{
        display: none;
    }
    .curso-subtitle{
        font-size: 27px;
        margin-top: 0px;
    }
    .btn-matricula1{
        font-size: 20px;
    }
    .curso-header12{
        gap: 0px;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 0px;
        
    }
    .btn-matricula12{
        font-size: 17px;
    }
    .contact-icon1{
        font-size: 25px;
    }
    .ctt-ctt1{
        font-size: 19px;
    }
    .contact-info22{
        flex-direction: column;
        width: 100%;
        
    }
    .grey2{
        display: none;
    }
    .grey1{
        width: 100%;
        margin-bottom: 16px;
    }

}